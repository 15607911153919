.toturial {
  background-color: #95c5c8;
  grid-column: span 25;
  grid-row: 6 / 7;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  /* grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));  */
  grid-template-rows: 105px 105px 105px 105px 105px 105px 105px 105px 105px 120px;
  position: relative;
  z-index: 1;
}

.sec5-text {
  grid-column: span 6;
}

.image1 {
  grid-area: 3/9/8/12;
  position: relative;
}


.image2 {
  grid-area: 6/7/10/10;
  position: relative;
}


.image3 {
  grid-area: 1/7/5/10;
  position: relative;
}

.image1 img,
.image2 img,
.image3 img {
  width: 100%;
  height: 100%;
  border-radius: 8px;


}

.image1::before,
.image2::before,
.image3::before {
  content: "";
  position: absolute;
  background: #0a080847;
  width: 100%;
  height: 100%;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  border-radius: 8px;
}

.image1:hover::before,
.image2:hover::before,
.image3:hover::before {
  background: #f9f9f927;
}

.text1 {
  grid-area: 4/2/6/5;
}

.text2 {
  grid-area: 6/2/8/5;
}

/* mwdia quiry */

@media screen and (max-width:480px) {
  .toturial {
    grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));
    height: 1205px;
  }

  .toturial .btn {
    width: 165px;
    font-size: 15px;
  }

  .toturial .image2,
  .toturial .image1 {
    display: none;
  }

  .image3 {
    grid-area: 7/1/12/12;
    margin: 0 10px;
  }

  .text1 {
    grid-area: 3/1/5/5;
    width: 60%;
    margin: 0 auto;
  }

  .text1 p,
  .text2 p {
    font-size: 14px;
    width: 100%;
    text-align: left;
  }

  .text1 h3,
  .text2 h3 {
    font-size: 18px;
  }

  .text2 {
    grid-area: 5/1/7/5;
    width: 60%;
    margin: 0 auto;
  }
}