.exam{
  background-color: #60b8cb;
  grid-column: span 25;
  grid-row: 9 / 10;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); 
  /* grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));  */
  grid-template-rows: 105px 105px 105px 105px 105px 105px 105px 105px 105px 120px; 
  position: relative;
  z-index: 1;
  
}
.sec8-text{
  grid-column: span 9;
}
.sec8-image{
  grid-area: 3/4/9/10;
  position: relative;
}
.sec8-image::before{
content: "";
content: "";
position: absolute;
width: 100%;
height: 100%;
background: #60b8cb36;
-moz-border-radius:50% ;
-ms-border-radius:50% ;
-o-border-radius:50% ;
-webkit-border-radius:50% ;
border-radius:50% ;
}
.sec8-image img{
  width: 100%;
  height: 100%;
  -moz-border-radius:50% ;
  -ms-border-radius:50% ;
  -o-border-radius:50% ;
  -webkit-border-radius:50% ;
  border-radius:50% ;
}

.sec8-text-1,
.sec8-text-2,
.sec8-text-3,
.sec8-text-4,
.sec8-text-5,
.sec8-text-6{
  position: absolute;
  padding: 10px;
  border-radius: 6px;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}
.sec8-text-1,
.sec8-text-2,
.sec8-text-3{
  background: linear-gradient(636.41deg, #60b8cb 1.14%, #5b7da3 100.75%);;
}
.sec8-text-4,
.sec8-text-5,
.sec8-text-6{
  background: linear-gradient(83deg, #60b8cb 1.14%, #5b7da3 100.75%);
}
.sec8-text-1:hover,
.sec8-text-2:hover,
.sec8-text-3:hover,
.sec8-text-4:hover,
.sec8-text-5:hover,
.sec8-text-6:hover{
  background: #5c87aa ;
}
.sec8-text-1{
  bottom: 226px;
  right: 10px;
  width: 390px;
}
.sec8-text-2{
  right: 10px;
  top: 20%;
}
.sec8-text-3{
  right: 10px;
  top: 44%;
  width: 284px;
}

.sec8-text-4{
  bottom: 226px;
  left: 10px;
  width: 390px;
}
.sec8-text-5{
  left: 10px;
  top: 20%;
}
.sec8-text-6{
  left: 10px;
  top: 44%;
  width: 284px;
}

.sec8-text-3 h3{
  margin-left: 20px;
}
.sec8-image::after{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 25px solid #60b8cb;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}
@media screen and (max-width:480px) {
  .exam{
    height: 1385px;    position: relative;
  }
  .sec8-image {
    grid-area: 8/1/12/10;
  }
  .sec8-image::after {
  display: none;
  }
  .sec8-text-5 {
    top: 34%;
  }
  .sec8-text-2 {
    top: 15%;
  }
  .sec8-text-6 {
    left: 10px;
    top: 21%;
    width: 284px;
  }
  .sec8-text-1 {
    bottom: 67%;
  }
  .sec8-text-3 {
    top: 40%;
  }
  .sec8-text-4 {
    bottom: 48%;
  }
  .sec8-image img {
    height: 72%;
}
.sec8-image::before {
  height: 72%;
}
}