.footer{
  background-color: #006064;
  grid-area: foot;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
  .footer-logo h1{
    font-family: 'Borel', cursive;
  }

  .footer-links{
    display: flex;
    flex-direction: column;
  }
  .footer-links .link{
    text-decoration: none;
    padding: 14px;
    color: #fff;
  }
  .footer-links .link:hover{
    color: wheat;
  }
  .footer .top{
    display: flex;
    justify-content: space-between;
  }
  .footer hr {
    width: 100%;
  }
  .footer-icon{
    display: flex;
    justify-content: center;
  }
  .footer-icon .f-icon{
    font-size: 24px;
    padding: 11px;
    margin: 10px;
    border: 1px solid #006064;
    color: black;
    -webkit-transition:0.5s;
    -moz-transition:0.5s;
    -ms-transition:0.5s;
    -o-transition:0.5s;
    transition:0.5s;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
}
  .footer-icon .face:hover{
    /* border: 1px solid #0a68dc; */
    color:white ;
    background-color:#0a68dc ;
  }
  .footer-icon .twet:hover{
    background-color:#1a9cfe ;
    color:white ;
  }
  .footer-icon .inst:hover{
    background-color:#f5464f;
    color:white ;
  }
  .footer-icon .tele:hover{
    background-color:#2897dd ;
    color:white ;
  }
  .footer-icon .wapp:hover{
    background-color:#2ad248 ;
    color:white ;
  }
.copy-right{
  display: flex;
  justify-content: center;
  color: wheat;
  cursor: help;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}
.copy-right:hover{
  color: white;
}
.copy-right:hover span{
  color: #18ffff;
}
.copy-right span{
  color: #95c5c8;
  margin: 0 3px;
}

@media screen and (max-width:480px) {
.footer{
  height: 600px;
}
  
}