/* Start Navbar Styling*/
.navbar{
  background-color: var(--Color-100); 
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 65px;
}
.navbar:hover{
  background-color: #75b9c2;
}
.logo{
  width: 150px;
}
.logo_img{
  margin-left: 5px;
  margin-top: 5px;
}
.menu-items{
  display: flex;
  gap: 12px;
  list-style: none;
  z-index: 100;
  margin-right: 10px;
}

.menu-items li a {
  text-decoration: none;
  color: var(--Color-900);
  font-weight: 600;
}

.menu-items li a:hover{
  color: antiquewhite;
}









/* End Navbar Styling*/
