.contactus{
  background-color: #66b1bb9e;
  grid-column: span 25;
  grid-row: 13 / 14;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); 
  grid-template-rows: 105px 105px 105px 105px 105px 105px 105px 105px 50px; 
  position: relative;
  z-index: 1;
}
.sec12-text{
  grid-column: span 8;
}
.sec12-text .icon{
margin-left: 15px;
}
.container-form{
  grid-area: 3/8/9/13;
  background-image: url("../../../image/Contact\ us\ concept.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  margin-right: 10px;
  position: relative;
}
.container-form form{
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
  z-index: 100;
}
.container-form form .icon{
  margin-right: 10px;
  color: #00b8d4;
}
.container-form form input{
  height: 62px;
  background: transparent;
  outline: none;
  border-top: transparent;
  border-right: transparent;
  border-bottom:3px solid #01579b ;
  border-left:transparent ;
  margin-bottom: 50px
}
.container-form form input{
color: #18ffff;
}


.container-form form label{
  color: #c5e1ff;
}
.container-form ::placeholder{
  color:  white;
}


.container-form::after{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #0a080887;
  border-radius: 15px;
  top: 0;
  left: 0;
}


.contactus::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 200px;
  background: #B2EBF2;
  top: 60%;
  transform: translateY(-50%);
  left: 0;
  z-index: -1;
  box-shadow: 0 0 20px 1px #006064;
}
.sec12-image{
  grid-area: 4/1/8/5;
  position: relative;
}
.sec12-image::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #00000047;
  margin-left: 10px;
  border-radius: 65px;
}
.sec12-image img{
  width: 100%;
  height: 100%;
  border-radius: 65px;
  margin-left: 10px;
}

.form-btn{
  padding: 14px;
  border-radius: 30px;
  outline: none;
  border: none;
  font-size: 16px;
  width: 172px;
  margin: 2px auto;
  color: white;
  background: radial-gradient(transparent, #5b7da3);
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  cursor: pointer;
}
.form-btn:hover{
  color: black;
  background: radial-gradient(#9ed2d4, transparent);
}

@media screen and (max-width:480px) {
  .container-form {
    grid-area: 5/1/11/13;
  margin: 10px;

}
.sec12-image {
  grid-area: 3/1/5/3;
}


}
