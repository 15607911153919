.word {
  background-color: #95c5c8;
  grid-column: span 25;
  grid-row: 8 / 9;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  /* grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));  */
  grid-template-rows: 105px 105px 105px 105px 105px 105px 105px 105px 105px 120px;
  position: relative;
  z-index: 1;
}

.sec7-text {
  grid-column: span 10;
}

.sec7-image {
  grid-area: 3/1/10/13;
  position: relative;
}

.sec7-image::before {
  content: "";
  position: absolute;
  background: linear-gradient(540deg, #00a0d2 -10.86%, #95c5c8 109.75%);
  width: 100%;
  height: 50px;
  bottom: 0;
}

.sec7-image::after {
  content: "";
  position: absolute;
  background: linear-gradient(360deg, #00a0d2 -10.86%, #95c5c8 109.75%);
  width: 100%;
  height: 50px;
  top: 0;
  left: 0;
}

.sec7-image img {
  width: 100%;
  height: 100%;
}

.sec7-text1 .icon,
.sec7-text2 .icon {
  color: #01579b;
  font-size: 30px;
  margin-right: 10px;
}

.text-container {
  height: 100%;
  width: 100%;
  grid-area: 7/1/9/12;
  z-index: 1;
  display: flex;
  flex-direction: row;
  margin-left: 35px;
  gap: 25px;
  align-items: center;
}

@media screen and (max-width:480px) {
  .word {
    height: 1045px;
  }

  .text-container {
    width: 91%;
    display: flex;
    flex-direction: column;
    grid-area: 4/1/9/12;
  }

  .sec7-image img {
    display: none;
  }


}