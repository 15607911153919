.community{
  background-color: #70b9c8;
  grid-column: span 25;
  grid-row: 11 / 12;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); 
  /* grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));  */
  grid-template-rows:  105px 105px 105px 105px 105px 105px 105px 105px 120px; 
  position: relative;
  z-index: 1;
  
}
.sec10-text {
  grid-column: span 9;
}
.sec10-image{
  grid-area: 3/8/9/13;
  position: relative;
  margin-right: 5px;
}
.sec10-image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #00000047;
  top: 0;
  left: 0;
  border-radius: 10px;
}
.sec10-image img{
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.heading{
  grid-area: 4/1/6/7;
margin-left: 10px;
}
.paragraph{
  grid-area: 6/1/9/7;
  margin-left: 20px;
  margin-bottom: 10px;
}
.paragraph p{
  margin-bottom: 20px;
  border-right: 7px solid #01579b;
  background: linear-gradient(99deg, #70b9c8 31.14%, #6aa7b4 100.75%);
  padding: 10px;
}


@media screen and (max-width:480px) {
  .community{
    height: 1385px;
  }
  .paragraph {
    grid-area: 10/1/12/7;
    margin: 0 10px;
}
.heading {
  grid-area: 3/1/6/7;
}
.heading h1 {
  font-size: 25px;
}
.sec10-image {
  grid-area: 5/1/10/13;
  margin: 0 5px;
}
}