.whous{
  background-color: #48949e9e;
  grid-column: span 25;
  grid-row: 12 / 13;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); 
  /* grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));  */
  grid-template-rows: 105px 105px 105px 105px 105px 105px 105px 105px 105px 120px; 
  position: relative;
  z-index: 1;
}
.sec11-text{
  grid-column: span 8;
}
.container{
  grid-area:4/1/10/13 ;
  background-color: #6ca2ad;
  position: relative;
  display: flex;
}
.container::before{
  content: "";
  position: absolute;
  background: linear-gradient(0deg, #8dbcc3 4.14%, #6ca2ad 31.75%);
  width: 100%;
  height: 50px;
  bottom: 0;
}
.container::after{
  content: "";
  position: absolute;
  background: linear-gradient(180deg, #8dbcc3 4.14%, #6ca2ad 31.75%);
  width: 100%;
  height: 50px;
  top: 0;
  left: 0;
}
.image-r {
  position: absolute;
  right: 0;
  top: -160px;
  z-index: 1;
}
.image-r::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #00000026;
  border-radius: 50%;
}
.image-r img{
  border-radius: 50%;
}
.text-l h1{
font-size: 50px;
font-family: 'Borel', cursive;
}
.text-l{
  margin-top: 50px;
  margin-left: 10px;
  width: 700px;
}
@media screen and (max-width:480px) {
  .whous{
    height: 1225px;
  }
  .container {
    grid-area: 5/1/12/13;
}
.image-r {
  top: -269px;
}
.image-r img {
  width: 290px;
}
}