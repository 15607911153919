.tools{
  background-color: var(--Color-900);
  /* background: var(--Color-leaner-card); */
  grid-column: span 25;
  grid-row: 4 / 5;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); 
  grid-template-rows: auto 1fr 150px; 
  position: relative;
  z-index: 1;
}

.sec3-text{
  grid-column: span 6;
}
.sec3-text>:nth-child(2){
  margin-left: 10px;
}

.containrt{
  grid-column: span 6;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); 
  display: grid;
  justify-items: center;
  gap: 13px;
}
.card{
  grid-column: span 2;
  width: 325px;
  height: 365px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 2px solid #70d68c;
  /* background: var(  --Color-leaner-card); */
  justify-content: center;
  border-radius: 8px;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
  box-shadow:  0px 0px 3px 0px #70d68c;
}

.card:hover{
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px);
}
.card:hover p{
color: white;
}


.card img{
  width: 100px;
}

.card-text{
  padding: 10px;
}
.card-text h1{
  text-align: center;
}
.containrt>:nth-child(2)> img{
  width: 150px;
}
.containrt>:nth-child(6)> img{
  width: 117px;
}

@media screen and (max-width: 480px) {
  .sec3-text>:nth-child(2) {
    font-size: 12px;
}
.card img {
  width: 76px;
}
.card-text h1 {
  font-size: 21px;
}
.card p {
  margin-left: 10px;
  color: white;
  font-size: 15px;
}
.card {
  height: 310px;
  width: 90%;

}
.tools .btn{
  width: 150px;
}
.tools .containrt{
  width: 100%;
}
}
