.design{
  background: linear-gradient(374deg, #16979c 31.14%, #18FFFF 100.75%);
  grid-column: span 25;
  grid-row: 5 / 6;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); 
  grid-template-rows: repeat(auto-fill, minmax(100px, 1fr)); 
  position: relative;
  z-index: 1;
  height: 1000px;
}

.sec4-text{
  grid-area: 1 / 1 / 1 /10;
}

.text4{
  grid-area: 4 / 1 / 6 /5;
  position: relative;
}
.text4 .icon{
  font-size: 40px;
  position: absolute;
  bottom: 90px;
  left: 98px;
  transform: rotate(-19deg);
  color: #bbc2bc;
}
.text4 h1{
  font-family: 'Borel', cursive;
  font-size: 100px;
  margin-left: 20px;
}

.galiry{
  grid-area: 3/5/9/13;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); 
  grid-template-rows: repeat(auto-fill, minmax(100px, 1fr)); 
  margin-right: 10px;
}

/* start image one */
.galiry .image1 {
  grid-area: 1/1/5/9;
  position: relative;
  height: 400px;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  overflow: hidden;
}

.galiry .image1::before{
  content: "";
  position: absolute;
  background: #00000000;
  width: 100%;
  height: 100%;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}

.galiry .image1 img {
  width: 843px;
  height: 400px;
}
.galiry .image1 p {
  position: absolute;
  bottom: -116px;
  color: white;
  font-size: 20px;
  padding: 28px;
  opacity: 0;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}
/* end image one */

/* start image two */

.galiry .image2 {
grid-area: 2/1/6/9;
position: relative;
-webkit-transition: 1s;
-moz-transition: 1s;
-ms-transition: 1s;
-o-transition: 1s;
transition: 1s;
overflow: hidden;
}

.galiry .image2::before{
  content: "";
  position: absolute;
  background: #00000000;
  width: 100%;
  height: 100%;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}

.galiry .image2 img {
  width: 843px;
  height: 400px;
}
.galiry .image2 p {
  position: absolute;
  bottom: -116px;
  color: white;
  font-size: 20px;
  padding: 28px;
  opacity: 0;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}
/* end image two */


/* start image three */
.galiry .image3 {
  grid-area: 3/1/5/9;
  position: relative;
  height: 400px;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  overflow: hidden;
}

.galiry .image3::before{
  content: "";
  position: absolute;
  background: #00000000;
  width: 100%;
  height: 100%;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}

.galiry .image3 img {
  width: 843px;
  height: 400px;
}
.galiry .image3 p {
  position: absolute;
  bottom: -116px;
  color: white;
  font-size: 20px;
  padding: 28px;
  opacity: 0;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}
/* start image three */


/* start image four */

.galiry .image4 {
grid-area: 4/1/5/9;
position: relative;
height: 400px;
-webkit-transition: 1s;
-moz-transition: 1s;
-ms-transition: 1s;
-o-transition: 1s;
transition: 1s;
overflow: hidden;
}

.galiry .image4::before{
  content: "";
  position: absolute;
  background: #00000000;
  width: 100%;
  height: 100%;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}

.galiry .image4 img {
  width: 843px;
  height: 400px;
}

.galiry .image4 p {
  position: absolute;
  bottom: -116px;
  color: white;
  font-size: 20px;
  padding: 28px;
  opacity: 0;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}

/* end image four */





/* hover all image */
.galiry .image1:hover + .image2,
.galiry .image1:hover ~ .image3,
.galiry .image1:hover ~ .image4{
  -webkit-transform: translateY(50px);
  -moz-transform: translateY(50px);
  -ms-transform: translateY(50px);
  -o-transform: translateY(50px);
  transform: translateY(50px);
  opacity: 0;
}



.galiry .image1:hover {
  -webkit-transform: translateY(-40px);
  -moz-transform: translateY(-40px);
  -ms-transform: translateY(-40px);
  -o-transform: translateY(-40px);
  transform: translateY(-40px);
}






.galiry .image2:hover{
  -webkit-transform: translateY(-30px);
  -moz-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  -o-transform: translateY(-30px);
  transform: translateY(-30px);
}


.galiry .image2:hover ~ .image4,
.galiry .image2:hover + .image3{
  -webkit-transform: translateY(50px);
  -moz-transform: translateY(50px);
  -ms-transform: translateY(50px);
  -o-transform: translateY(50px);
  transform: translateY(50px);
  opacity: 0;
}







.galiry .image3:hover{
  -webkit-transform: translateY(-20px);
  -moz-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  -o-transform: translateY(-20px);
  transform: translateY(-20px);
}
.galiry .image3:hover + .image4{
  -webkit-transform: translateY(50px);
  -moz-transform: translateY(50px);
  -ms-transform: translateY(50px);
  -o-transform: translateY(50px);
  transform: translateY(50px);
  opacity: 0;
}




.galiry .image1:hover p,
.galiry .image2:hover p,
.galiry .image3:hover p,
.galiry .image4:hover p{
bottom: 0;
opacity: 1;
}
.galiry .image4:hover{
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px);
}

.galiry .image1:hover::before,
.galiry .image2:hover::before,
.galiry .image3:hover::before,
.galiry .image4:hover::before{
background-color: #000000b0;
}

/* mwdia quiry */

@media screen and (max-width:480px) {
    .design {
      height: 1160px;
  }
  .sec4-text h3{
    font-size: 13px;
    width: 100%;
    }
    .galiry {
      grid-area: 4/1/9/13;
      margin: 0 5px;
  }
  .text4 {
    grid-area: 3 / 1 / 4 /5;
  }
  .text4 h1 {
    font-size: 50px;
    text-align: center;
    margin: 0;
  }
  .text4 .icon {
    font-size: 18px;
    left: 200px;
  }
  .design .btn{
    width: 166px;
    font-size: 15px;
  }
}