.ask {
  background-color: #80deea9e;
  grid-column: span 25;
  grid-row: 10 / 11;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  /* grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));  */
  grid-template-rows: 105px 105px 105px 105px 105px 105px 105px 105px 105px 120px;
  position: relative;
  z-index: 1;
}

.sec9-text {
  grid-column: span 7;
}

.sec9-image {
  grid-area: 1/8/10/13;
  position: relative;
}

.sec9-image::before {
  content: "";
  position: absolute;
  width: 5%;
  height: 100%;
  left: -26px;
  background: linear-gradient(270deg, #14b1b2 -10.86%, #b0eaf2 109.75%);
}

.sec9-image::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #00000047;
  top: 0;
  left: 0;
}

.sec9-image img {
  width: 100%;
  height: 100%;
}

.sec9-text-1 {
  bottom: 33%;
  left: 12px;
}

.sec9-text-2 {
  left: 10px;
  top: 28%;
}

.sec9-text-3 {
  left: 10px;
  top: 44%;
}

.sec9-text-4 {
  bottom: 166px;
  left: 10px;
}

.sec9-text-1,
.sec9-text-2,
.sec9-text-3,
.sec9-text-4 {
  position: absolute;
  padding: 10px;
  border-radius: 6px;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  width: 610px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sec9-text-1,
.sec9-text-2,
.sec9-text-3,
.sec9-text-4 {
  background: linear-gradient(636.41deg, #60b8cb 1.14%, #cf772e9c 100.75%);
}

.ask .icon {
  font-size: 20px;
  color: #b25609;
  margin-left: 5px;
}

.ask .icon-r {
  color: #0c0907;
  margin-right: 5px;
}

@media screen and (max-width:480px) {

  .sec9-text-1,
  .sec9-text-2,
  .sec9-text-3,
  .sec9-text-4 {
    width: 90%;
  }

  .ask .icon-r {
    display: none;
  }

  .sec9-text-2 {
    top: 20.5%;
  }

  .sec9-text-3 {
    top: 42%;
  }

  .sec9-text-1 {
    bottom: 31%;
  }

  .sec9-text-4 {
    bottom: 13%;
  }

  .sec9-image {
    grid-area: 3/1/10/13;
    position: relative;
  }


}