.project{
  background-color: var(--Color-100);
  grid-column: span 25;
  grid-row: 7 / 8;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); 
  grid-template-rows:  105px 105px 105px 105px 105px 105px 105px 105px 120px;
  position: relative;
  z-index: 1;
}

.sec6-text{
  grid-column: span 8;
}
.sec6-image-r{
  grid-area: 3/7/9/13;
}
.sec6-image-r img{
    width: 100%;
    height: 100%;
    -webkit-border-radius:8px ;
    -moz-border-radius:8px ;
    -ms-border-radius:8px ;
    -o-border-radius:8px ;
    border-radius:8px ;
}
.sec6-text1{
  grid-area: 4/1/5/6;
}
.sec6-text2{
  grid-area: 5/1/6/6;
}
.sec6-text3{
  grid-area: 6/1/7/7;
}
.sec6-text4{
  grid-area: 7/1/8/7;
}
.sec6-text1 ,
.sec6-text2 ,
.sec6-text3 ,
.sec6-text4 {
  display: flex;
  align-items: center;
}
.sec6-text1 .icon,
.sec6-text2 .icon,
.sec6-text3 .icon,
.sec6-text4 .icon{
  font-size: 32px;
  margin-left: 10px;
  margin-right: 5px;
  color: var(--Color-900);
}
@media screen and (max-width:480px) {
  .project {
    height: 1090px;
  }
  .sec6-text1 {
    grid-area: 3/1/4/6;
  }
  .sec6-text2 {
    grid-area: 4/1/5/6;
  }
  .sec6-text3 {
    grid-area: 5/1/6/7;
  }
  .sec6-text4 {
    grid-area: 6/1/7/7;
  }
  .sec6-image-r {
    grid-area: 7/1/10/5;
    margin: 0 45px;
  }
  .sec6-text1,
  .sec6-text2,
  .sec6-text3,
  .sec6-text4{
    font-size: 16px;
  }
  .sec6-text1 .icon,
  .sec6-text2 .icon,
  .sec6-text3 .icon,
  .sec6-text4 .icon {
    font-size: 22px;
    margin-left: 5px;
  }
  .project .btn {
    width: 170px;
  }
}