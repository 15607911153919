
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}
:root{
  --Color-100:#B2EBF2;
  --Color-200:#80DEEA;
  --Color-300:#4DD0E1;
  --Color-400:#26C6DA;
  --Color-500:#00BCD4;
  --Color-600:#00ACC1;
  --Color-700:#0097A7;
  --Color-800:#00838F;
  --Color-900:#006064;
  --Color-1000:#5b7da3;
  --Color-1100:#00d5a5;
  --Color-A100:#84FFFF;
  --Color-A200:#18FFFF;
  --Color-A400:#00E5FF;
  --Color-A700:#00B8D4;
  --Color-blur:#00b8d4c9;
  --Color-overlay:#00ffff24;
  --Color-over1:#23036a;
  --Color-over2:#6200ee;
  --Color-light:#E0F7FA;
  --Color-heading1:#371583;
  --Color-heading2:#01579B;
  --Color-leaner:linear-gradient(210.41deg, #01579B 1.14%, #18FFFF 100.75%);
  /* --Color-leaner-card:linear-gradient(179deg, #006064 31.14%, #18FFFF 100.75%); */
  --Color-leaner-card:linear-gradient(297deg, #16979c 31.14%, #18FFFF 100.75%);
  --main-color: #2196f3;
  --color-sec-r: #e8ede9;
  --color-icon1: #6e9b94;
  --color-icon2: #286663;
  --color-icon-hover: #242936;
  --color-p: #7c9b96;
  --color-p-hover: wheat;
  --color-h2: #999c43;
  --color-h2-hover: rgb(201, 169, 110);
  --main-transition: 0.3s;
}
.App{
  height: 1100px;
  display: grid;
  grid-template-columns: repeat(12 ,1fr);
  grid-template-rows:1fr 380px ;
  grid-template-areas: 
  "cont cont cont cont cont cont cont cont cont cont cont cont"
  "foot foot foot foot foot foot foot foot foot foot foot foot";
}

/* .stroke-text{
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  } */

  .head-text {
    font-size: 50px;
    font-family: 'Oswald', sans-serif;
  }
  .cont-head-text {
    margin-left: 10px;
  }

  .blur-t{
  filter: blur(8px) grayscale(70%);
  background: var(--Color-blur);
  position: absolute;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -webkit-filter: blur(150px);
  -moz-filter: blur(150px);
  -ms-filter: blur(150px);
  filter: blur(150px);
  z-index: 20000;
  width: 300px;
  height: 300px;
  top: 82px;
}
  .blur-c{
    filter: blur(8px) grayscale(70%);
    background: #4dd0e1;
    position: absolute;
    border-radius: 50%;
    -moz-filter: blur(150px);
    -ms-filter: blur(150px);
    filter: blur(174px);
    z-index: 20000;
    width: 300px;
    height: 300px;
    top: 82px;
}

  /* btn */
  .btn{
    position: absolute;
    bottom: 22px;
    left: 50%;
    transform: translateX(-50%);
    text-decoration: none;
    border: 2px solid #551a8b;
    padding: 15px 30px;
    overflow: hidden;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    border-radius: 40px;
    color:#e0f7fa ;
    -webkit-transition: 1s;
    -moz-transition: 1s;
    -ms-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
    font-size: 18px;
    font-weight: bold;
  }
  .btn:hover{
  color: #01579B;
  }
  .btn::before{
    content: '';
    display: block;
    width: 1px;
    height: 23px;
    padding: 15px 30px;
    background: antiquewhite;
    border-radius: 50%;
    position: absolute;
    left: -35px;
    bottom: -2px;
    -webkit-transition: 1.2s;
    -moz-transition: 1.2s;
    -ms-transition: 1.2s;
    -o-transition: 1.2s;
    transition: 1.2s;
    z-index: -1;
  }
  .btn:hover::before{
    width: 200px;
    height: 54px;
    left: -3px;
    bottom: -17px;
  }
  .btn:focus{
    color: #588865;
    border-color: #588865;
  }
  .btn:hover .icon-btn{
    -webkit-animation:  moving-arrow 0.6s linear 0.6s infinite;
    -moz-animation:  moving-arrow 0.6s linear 0.6s infinite;
    -ms-animation:  moving-arrow 0.6s linear 0.6s infinite;
    animation:  moving-arrow 0.6s linear 0.8s infinite;
  }
  @keyframes moving-arrow {
    100% {
      transform: translateX(10px);
    }
  }
    .icon-btn{
      color: var(--Color-heading2);
      margin-left: 5px;
    }

    @media screen and (max-width:480px) {
      .head-text {
        font-size: 37px;
        font-family: 'Oswald', sans-serif;
        margin-left: 11px;
      }

      .sec1-text h3,
      .sec2-text h3,
      .sec3-text h3,
      .sec4-text h3,
      .sec5-text h3,
      .sec6-text h3,
      .sec7-text h3,
      .sec8-text h3,
      .sec9-text h3,
      .sec10-text h3,
      .sec11-text h3,
      .sec12-text h3{
        font-size: 15px;
      }
    .btn {
      padding: 12px 16px;
      font-size: 16px;
      color: #006064;
    }
    }