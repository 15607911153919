.start {
  background-color: var(--Color-100);
  grid-column: span 25;
  grid-row: 1 / 2;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); 
  width: 100%;
}
.sec-start-l{
  grid-column: span 3;
}
.sec-start-r{
  grid-column: span 3;
}
.sec0-image::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--Color-overlay);
  top: 0;
  border-radius: 15px;
  transform: rotate(1deg);
  z-index: 1;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  -webkit-transform: rotate(1deg);
  -moz-transform: rotate(1deg);
  -ms-transform: rotate(1deg);
  -o-transform: rotate(1deg);
}
.sec0-image{
  position: relative;
  transform: rotate(8deg);
}
.overlay:hover{
background-color: #b2ebf2b3;
}
.over-1 ,
.over-2 ,
.over-3 {
  width: 373.9px;
  height: 662.72px;
  border-radius: 15px;
  position: absolute;
}

.over-1 {
  background-color: transparent;
  border: 2px solid var(--Color-over1);
  -webkit-transform: rotate(6deg);
  -moz-transform: rotate(6deg);
  -ms-transform: rotate(6deg);
  -o-transform: rotate(6deg);
  transform: rotate(6deg);
}
.over-2 {
  background-color: transparent;
  border: 2px solid var(--Color-500);
  -webkit-transform: rotate(-1deg);
  -moz-transform: rotate(-1deg);
  -ms-transform: rotate(-1deg);
  -o-transform: rotate(-1deg);
  transform: rotate(-1deg);
}
.over-3 {
  background-color: transparent;
  border: 2px solid var(--Color-900);
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  transform: rotate(3deg);
}

.start .background{
  margin-top: 103px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-around;
  position: relative;
}
.start .background img{
  width: 375px;
  height: 665px;
  border-radius: 15px;
  transform: rotate(1deg);
}

.sec-start-l{
  margin-top: 103px;
  display: flex;
  align-items: center;
}
.sec-start-l .sec-start-text-l{
margin-left: 10px;
}
.sec-start-l .sec-start-text-l>:nth-child(2){
color: var(--Color-heading1);
}
.sec-start-l .sec-start-text-l>:nth-child(4){
line-height: 1.5;
font-size: 18px;
color: var(--Color-heading2);
}

/* mwdia quiry */

@media screen and (max-width:480px) {
  .start {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}
  .logo_img{
    width: 85px;
  }
  .sec-start-text-l h1 {
    font-size:  41px;
  }
  .sec-start-text-l h2 {
    font-size: 20px;
  }
  .sec-start-text-l p {
    font-size: 17px;
  }
  .sec-start-text-l>:nth-child(4) {
    width: 93%;
  }
  .start .background img {
    width: 235px;
    height: 390px;
    transform: rotate(1deg);
    -webkit-transform: rotate(1deg);
    -moz-transform: rotate(1deg);
    -ms-transform: rotate(1deg);
    -o-transform: rotate(1deg);
}
.start .background {
  margin-top: 37px;
}
.over-1,
.over-2,
.over-3{
  width: 235px;
  height: 390px;
}
}
@media screen and (min-width: 481px) {


}
@media screen and (min-width: 481px) {


}
@media screen and (min-width: 481px) {


}