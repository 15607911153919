.learn {
  background-color: var(--Color-300);
  grid-column: span 25;
  grid-row: 2 / 3;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  position: relative;
  z-index: 1;
  min-height: 800px;
}

.sec1-l {
  grid-column: span 3;
}

.sec1-l .sec1-text-l h3 {
  margin-left: 10px;
}

.sec1-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}


.sec1-r {
  grid-column: span 3;
}

.sec1-text-r {
  position: absolute;
  top: 165px;
  right: 55px;
  padding: 10px;
  border-radius: 6px;
  background: var(--Color-leaner);
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}

.sec1-text-r:hover {
  transform: translateY(-20px);
}

.icon-check {
  color: var(--Color-heading2);
}

/* mwdia quiry */

/* very small media */
@media (max-width: 480px) {
  .head-text {
    font-size: 37px;
    font-family: 'Oswald', sans-serif;
    margin-left: 11px;
  }

  .learn {
    min-height: 700px;
  }

  .sec1-text-r {
    width: 195px;
    height: 160px;
    left: 9px;
  }

  .sec1-text-r h1 {
    font-size: 19px;
  }

  .sec1-l .sec1-text-l h3 {
    font-size: 14px;
  }

  .sec1-image img {
    width: 415px;
  }

  .btn {
    padding: 12px 16px;
    font-size: 16px;
  }

  .sec1-image {
    top: 68%;
  }
}

/* small media */

/* @media (min-width: 481px) and (max-width: 767px){
  .learn{
    background-color: black;
  }
  
} */

/* medum media */
/* @media (min-width: 768px) and (max-width: 992px){
  .learn{
    background-color: red;
  }
  
} */

/* large media */
/* @media (min-width: 993px) and (max-width: 1200px){
  .learn{
    background-color: green;
  }
  
} */

/* xlarge media */
/* @media (min-width: 1201px) {
  .learn{
    background-color: blue;
  }
  
} */