.doc{
  background: #6d9c7a;
  grid-column: span 25;
  grid-row: 3 / 4;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); 
  grid-template-rows: auto 150px;
  position: relative;
  z-index: 1;
}

.doc-l{
  grid-column: span 3;
}

.sec2-text-l p{
  margin-left: 10px;
  font-size: 19px;
}

.sec2-image img{
  width: 490px;
  height: 535px;
  margin-left: 10px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;
}

.doc-r{
  grid-column: span 3;
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-items: flex-end;
}
.main-box{
    background: transparent;
    width: 500px;
    height: 600px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-around;
    position: relative;
}
  .main-box::before{
    content: "";
    position: absolute;
    background: #6d9c7a;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .main-box::after{
    content: "";
    position: absolute;
    background: var(--color-sec-r);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .main-box .box{
    background: var(--color-sec-r);
    width: 222px;
    height: 265px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}  
.main-box .box:hover {
background-color: #6d9c7a;
}
.main-box .box:hover h2 {
color: var(--color-h2-hover);
}
.main-box .box:hover p {
color: var(--color-p-hover);
}
.main-box .box:hover .icon-sec2-r{
color:var(--color-icon-hover) ;
}

.main-box .box .icon-sec2-r {
  font-size: 45px;
}
.sec2-text-r h2{
  text-align: center;
  color: var(--color-h2);
}
.sec2-text-r p{
  line-height: 1.2;
  color: var(--color-p);
}
.main-box>:nth-child(1) .icon-sec2-r
,.main-box>:nth-child(4) .icon-sec2-r{
color:var(--color-icon1) ;
}
.main-box>:nth-child(3) .icon-sec2-r,
.main-box>:nth-child(2) .icon-sec2-r{
color:var(--color-icon2) ;
}

@media screen and (max-width: 480px) {
.doc{
  height: 1635px;
}
.sec2-image img {
  width: 100%;
  height: 377px;
  margin: 0;
}
.main-box {
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  margin: 0 20px;
}
.main-box::before,
.main-box::after{
display: none;
}
.main-box .box {
  width: 100%;
  margin-bottom: 10px;
}
.doc .btn {
  width: 142px;
}
}